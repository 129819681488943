<template>
  <div class="online-order-wrapper page">
    <data-table
      :data="renderData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/dataTable'

export default {
  name: 'OnlineOrders',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters('onlineOrders', ['getData']),
    ...mapGetters('settings', ['getDefaultValues']),
    renderData () {
      return this.getData.map(item => {
        return {
          ...item,
          status: this.getStatusName(item.status),
        }
      })
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('onlineOrders', { loadDataOnlineOrders: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataOnlineOrders()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    getStatusName (status) {
      return this.getDefaultValues?.onlineOrderStatuses?.filter(({ code }) => code === status)[0]?.name
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />