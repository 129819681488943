import { mapGetters, mapActions } from 'vuex'
import { getTitleById } from '@/helper'
import page from '@/mixins/page'

const pageMethods = {
  mixins: [page],
  computed: {
    ...mapGetters('user', ['isAccessAllowed']),
    ...mapGetters('settings', [
      'getIsAllowedPage',
      'getHeaders',
      'getFields',
      'getDefaultValues'
    ]),
    ...mapGetters('categories', { getDataCategories: 'getData' }),
    ...mapGetters('products', { getDataProducts: 'getData' }),
    ...mapGetters('users', { getDataUsers: 'getData' }),
    ...mapGetters('workingShift', { getDataWorkingShift: 'getData' }),
    getHeadersToPage () {
      return this.getHeaders?.[this.nameActivePageForMethod] || []
    },
    getFieldsToPage () {
      return this.getFields?.[this.nameActivePageForMethod] || []
    },
    getFieldsCreateSales () {
      return this.getFields?.sales.create || {}
    },
    getFieldsCreateCashOperation () {
      return this.getFields?.cashOperation.create || {}
    },
    getFieldsCreate () {
      return this.getFieldsToPage.create
    },
    getFieldsChange () {
      return this.getFieldsToPage.change
    },
    getFieldsInfo () {
      return this.getFieldsToPage.info
    },
    isShowCreate () {
      return this.getIsAllowedPage('create', this.nameActivePageForMethod)
    },
    isShowChange () {
      return this.getIsAllowedPage('change', this.nameActivePageForMethod)
    },
    isShowInfo () {
      return !!this.getFieldsInfo
    },
    isShowReturn () {
      return this.nameActivePageForMethod === 'sales'
    },
    isShowActivateDeactivateUser () {
      return this.nameActivePageForMethod === 'users'
    },
    isShowRemove () {
      return this.getIsAllowedPage('remove', this.nameActivePageForMethod)
    },
    isShowMenu () {
      return this.isShowInfo || this.isShowChange || this.isShowReturn || this.isShowRemove
    },
    isShowSearch () {
      return [
        'cashOperation',
        'categories',
        'onlineOrders',
        'products',
        'sales',
        'salesUsers',
        'users',
        'workingShift'
      ].includes(this.nameActivePageForMethod)
    },
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('sales', ['returnSales']),
    ...mapActions('users', ['deactivate', 'activate']),
    ...mapActions('workingShift', ['getInfoById']),
    ...mapActions('info', ['loadMainData']),
    createValue (isInfo, fields, item, field, valueTitle, customDefaultValue) {
      const fieldsValue = fields[field]?.value || item[field]

      if (typeof fieldsValue === 'boolean') {
        return fieldsValue ? 'Да' : 'Нет'
      }

      // Для модалки с инфой, заполняем данные значением из title
      if (isInfo && valueTitle.includes(field)) {
        return getTitleById(fieldsValue, field)
      }

      // Если передали кастомное дефолтное значение
      if (customDefaultValue?.[field]) {
        return customDefaultValue?.[field]
      }

      return fieldsValue
    },
    getFieldsWithDefaultValue ({
      item = {},
      fields,
      hideSelectItem = {},
      isInfo = false,
      valueTitle = [],
      customDefaultValue = {}
    }) {
      if (!fields) {
        return
      }

      const fieldsKeys = Object.keys(fields)
      let result = {}

      const defaultValuesSelectItems = {
        ['categories']: !hideSelectItem?.['categories'] ? this.getDataCategories : [],
        ['workingShiftId']: !hideSelectItem?.['workingShift'] ? this.getDataWorkingShift : [],
        ['products']: !hideSelectItem?.['products'] ? this.getDataProducts : [],
        ['role']: !hideSelectItem?.['role'] ? this.getDefaultValues?.role?.filter(item => item.active) : [],
        ['typePay']: !hideSelectItem?.['typePay'] ? this.getDefaultValues?.typePay : [],
        ['operationType']: !hideSelectItem?.['operationType'] ? this.getDefaultValues?.operationType : [],
        ['status']: !hideSelectItem?.['status'] ? this.getDefaultValues?.onlineOrderStatuses : [],
      }

      if (this.isAccessAllowed) {
        defaultValuesSelectItems['userId'] = this.getDataUsers.map(item => {
          return {
            ...item,
            disabled: item?.status === 'deactivated'
          }
        })
      }

      fieldsKeys.forEach(field => {
        result[field] = {
          ...fields[field],
          selectItems: defaultValuesSelectItems[field] || [],
          value: this.createValue(isInfo, fields, item, field, valueTitle, customDefaultValue)
        }
      })

      return result
    },
    async createSales () {
      const fields = this.getFieldsWithDefaultValue({
        fields: this.getFieldsCreateSales,
        hideSelectItem: {
          'products': true
        }
      })

      const callback = async () => {
        await this.loadMainData(true)
        if (this.nameActivePageForMethod === 'main') {
          await this.$bus.emit('load-sales-main-data')
        }
      }

      await this.openModal({
        name: 'create-sales',
        title: 'Создание продажи',
        propsData: {
          name: this.getModalNameSales,
          page: 'sales',
          nameModuleSubmit: 'sales',
          callback,
          fields
        }
      })
    },
    async createCashOperations (sendCallback = false) {
      const fields = this.getFieldsWithDefaultValue({
        fields: this.getFieldsCreateCashOperation,
      })

      const callback = async () => {
        if (sendCallback) {
          await this.loadMainData(true)
        }
      }

      await this.openModal({
        name: 'create-modal',
        title:  'Создать операцию по кассе',
        propsData: {
          name: '',
          page: 'cashOperation',
          nameModuleSubmit: 'cashOperation',
          callback,
          fields
        }
      })
    },
    getCreateMethodWithPage () {
      switch (this.nameActivePageForMethod) {
        case 'sales':
          return this.createSales()
        case 'cashOperation':
          return this.createCashOperations()
        default:
          return this.createItem()
      }
    },
    async removeItem (item) {
      if (!this.isShowRemove) {
        return
      }

      await this.$store.dispatch(`${this.nameActivePageForMethod}/remove`, item.id)
    },
    async createItem () {
      if (!this.isShowCreate) {
        return
      }

      const customDefaultValue = {}

      if (this.nameActivePage !== 'users') {
        customDefaultValue.categories = this.getDataCategories[0].id
      }

      const fields = this.getFieldsWithDefaultValue({
        customDefaultValue,
        fields: this.getFieldsCreate
      })

      await this.openModal({
        name: 'create-modal',
        title: `Создание ${this.getModalName}`,
        propsData: {
          name: this.getModalName,
          page: this.nameActivePageForMethod,
          nameModuleSubmit: this.nameActivePage,
          fields
        }
      })
    },
    async changeItem (item) {
      if (!this.isShowChange) {
        return
      }

      const fields = this.getFieldsWithDefaultValue({
        fields: this.getFieldsChange,
        item
      })

      await this.openModal({
        name: 'change-modal',
        title: `Редактирование ${this.getModalName}`,
        propsData: {
          name: this.getModalName,
          page: this.nameActivePageForMethod,
          fields,
          item
        }
      })
    },
    async returnItemSales (item) {
      if (!this.isShowReturn) {
        return
      }

      await this.returnSales(item.id)
    },
    async deactivateUser (item) {
      if (!this.isShowActivateDeactivateUser) {
        return
      }

      await this.deactivate(item.id)
    },
    async activateUser (item) {
      if (!this.isShowActivateDeactivateUser) {
        return
      }

      await this.activate(item.id)
    },
    getTitleWithInfo (item) {
      switch (this.nameActivePageForMethod) {
        case 'sales':
        case 'salesUsers':
          return `Продажа за ${item.createdAt}`
        case 'workingShift':
          return `${item.openingAt} - ${item.closingAt}`
        case 'history':
          return `${item.type}`
        default:
          return item?.title || ''
      }
    },
    async infoItem (item) {
      if (!this.isShowInfo) {
        return
      }

      let extendedItem = {
        ...item
      }

      if (this.nameActivePageForMethod === 'workingShift') {
        const data = await this.getInfoById({ id: extendedItem.id })

        extendedItem = {
          ...item,
          ...data
        }
      }

      const hideSelectItem = {
        products: this.nameActivePageForMethod === 'salesUsers'
      }

      const fields = this.getFieldsWithDefaultValue({
        fields: this.getFieldsInfo,
        isInfo: true,
        valueTitle: [
          'categories',
          'products'
        ],
        hideSelectItem,
        item: extendedItem
      })

      await this.openModal({
        name: 'info-modal',
        title: this.getTitleWithInfo(item),
        propsData: {
          page: this.nameActivePageForMethod,
          fields,
          item: extendedItem
        }
      })
    }
  }
}

export default pageMethods