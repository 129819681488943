<template>
  <div class="wrapper-table">
    <el-card
      v-if="!hideBoxControl"
      class="box-card box-control"
      ref="boxControl"
    >
      <div class="block-buttons margin-top-0">
        <slot name="buttonBlock" />

        <el-button
          v-if="isShowButtonAdd"
          class="el-button-h-40"
          plain
          type="primary"
          @click="getCreateMethodWithPage"
        >
          <span>{{ textButtonAdd || 'Добавить' }}</span>
        </el-button>
      </div>
    </el-card>

    <el-card class="box-card box-table">
      <div
        class="table-header"
        ref="tableHeader"
      >
        <label class="main-label">
          <span class="main-label__title">Кол. элементов в таблице</span>
          <el-select
            v-model="stopRenderItem"
            placeholder="Выбирите"
          >
            <el-option
              v-for="item in viewItemsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </label>

        <div
          v-if="isShowSearch"
          class="table_search"
        >
          <label class="main-label">
            <span class="main-label__title">Поиск</span>

            <el-input
              v-model="searchText"
              placeholder="Поиск"
              @input="search"
            />
          </label>
        </div>
      </div>

      <el-table
        v-if="getHeadersToPage.length"
        :data="renderData"
        :height="'500px'"
        lazy
        style="width: 100%"
        :row-class-name="({ row }) => tableRowClasses(row)"
        @row-click="rowClick($event)"
      >
        <el-table-column
          v-for="header in getHeadersToPage"
          :key="header.value"
          :prop="header.value"
          :label="header.text"
          :width="header.width || 'auto'"
          :sortable="true"
          :resizable="false"
        />
        <el-table-column
          v-if="isShowTableMenu"
          fixed="right"
          label="Действия"
          width="120px"
          :resizable="false"
        >
          <template v-slot:default>
            <el-dropdown
              trigger="click"
              @command="handleClick($event)"
            >
              <span class="el-dropdown-link">
                Действия
                <i class="el-icon-arrow-down el-icon--right" />
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="isShowInfo"
                  command="info"
                >Информация</el-dropdown-item>
                <el-dropdown-item
                  v-if="!hideChange && isShowChange"
                  command="change"
                >Редактировать</el-dropdown-item>
                <el-dropdown-item
                  v-if="isShowButtonReturn"
                  command="return"
                  class="text--red"
                >Возврат</el-dropdown-item>
                <el-dropdown-item
                  v-if="isShowButtonDeactivate"
                  command="deactivate"
                  class="text--red"
                >Деактивировать</el-dropdown-item>
                <el-dropdown-item
                  v-if="isShowButtonActivate"
                  command="activate"
                  class="text--red"
                >Активировать</el-dropdown-item>
                <el-dropdown-item
                  v-if="!hideRemove &&isShowRemove"
                  command="remove"
                  class="text--red"
                >Удалить</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <div class="table-footer">
        <el-button
          class="button-arrow-back el-button-h-40"
          @click="back"
        >Назад</el-button>

        <span class="main-label__title">{{ getPaginationText }}</span>

        <el-button
          class="button-arrow-forth el-button-h-40"
          @click="forth"
        >Вперед</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import tableMethods from '@/mixins/tableMethods'

export default {
  name: 'Table',
  mixins: [tableMethods],
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    hideUpdate: {
      type: Boolean,
      default: false
    },
    hideCreate: {
      type: Boolean,
      default: false
    },
    hideRemove: {
      type: Boolean,
      default: false
    },
    hideChange: {
      type: Boolean,
      default: false
    },
    hideBoxControl: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    removeHeight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchText: '',
      selectItem: null,
      startRenderItem: 0,
      stopRenderItem: 20,
      viewItemsList: [
        {
          value: 20,
          name: '20'
        },
        {
          value: 40,
          name: '40'
        },
        {
          value: 80,
          name: '80'
        },
        {
          value: 160,
          name: '160'
        },
        {
          value: 320,
          name: '320'
        },
        {
          value: 640,
          name: '640'
        },
      ],
      activeEvent: null,
      mainData: this.data
    }
  },
  watch: {
    data (newVal) {
      this.mainData = newVal

      if (this.searchText.length) {
        this.search()
      }
    }
  },
  computed: {
    renderData () {
      return [...this.mainData].splice(this.startRenderItem, this.stopRenderItem)
    },
    isShowButtonAdd () {
      return !this.hideCreate && this.isShowCreate
    },
    isShowTableMenu () {
      return this.isShowMenu && this.getHeadersToPage && this.renderData
    },
    isShowButtonReturn () {
      return this.isShowReturn && !this.selectItem?.isReturned
    },
    isShowButtonDeactivate () {
      return this.isShowActivateDeactivateUser && this.selectItem?.status === 'activated'
    },
    isShowButtonActivate () {
      return this.isShowActivateDeactivateUser && this.selectItem?.status === 'deactivated'
    },
    getPaginationText () {
      const start = this.startRenderItem + 1
      const end = this.stopRenderItem + this.startRenderItem > this.mainData.length
        ? this.mainData.length
        : this.stopRenderItem + this.startRenderItem
      const count = this.mainData.length

      return `Показано с ${ start } по ${ end }. Всего ${ count } элемента`
    }
  },
  methods: {
    tableRowClasses (item) {
      const defaultClasses = 'table-row'
      let classes = ''

      if (['sales', 'salesUsers'].includes(this.nameActivePageForMethod) && item?.isReturned) {
        classes = 'table-row__returned'
      }

      if (['users'].includes(this.nameActivePageForMethod) && item?.status === 'deactivated') {
        classes = 'table-row__deactivate'
      }

      if (this.nameActivePageForMethod === 'workingShift' && item?.isOpened) {
        classes = 'table-row__opened'
      }

      return `${defaultClasses} ${classes}`
    },
    back () {
      const result = this.startRenderItem - this.stopRenderItem

      this.startRenderItem = result < 0 ? 0 : result
    },
    forth () {
      const result = this.startRenderItem + this.stopRenderItem

      this.startRenderItem = result < this.mainData.length ? result : this.startRenderItem
    },
    handleClick (action) {
      const commands = {
        info: this.infoItem,
        change: this.changeItem,
        return: this.returnItemSales,
        remove: this.removeItem,
        activate: this.activateUser,
        deactivate: this.deactivateUser
      }

      commands?.[action]?.(this.selectItem)
    },
    rowClick (item) {
      this.selectItem = item
    },
    search () {
      // TODO: СРОЧНО. Сделать запрос на бэк или перенести в воркер
      if (!this.searchText) {
        this.clearSearch()
      }

      const getFirstWords = (item) => {
        const words = item?.title
          || item?.productsNames
          || ''

        return words.toLowerCase()
      }

      const getSecondWords = (item) => {
        const words = item?.code || ''

        return words.toLowerCase()
      }

      this.mainData = this.data.filter(item => {
        const searchTextArray = this.searchText.toLowerCase().split(' ')

        return !Number(this.searchText)
          ? searchTextArray.every(str => getFirstWords(item).includes(str.toLowerCase()))
          : getSecondWords(item).includes(this.searchText.toLowerCase())
        })
    },
    clearSearch () {
      this.mainData = this.data
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
